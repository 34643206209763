.coupon-code{
    padding: 12px;
    background-color: #00800042;
    font-size: 0.8rem;
    height: 140px;
    border-radius: 5px;
    position: relative;
    margin: 14px;
}
.coupon-code-h1{
    font-size: 0.8rem;
    position: absolute;
    top: 74px;
    left: 152px;
    z-index: 1;
    color: rgb(33, 150, 17);
}
.coupon-code-h2{
    font-size: 0.8rem;
    position: absolute;
    top: 74px;
    left: 152px;
    z-index: 1;
    color: red;
}
.cart-item-cont{
    display:flex;
    padding:8px;
    border:1px solid #c7c7c7
}
.cart-item-img-cont{
    width: 20%;
    float:left
}
.cart-item-img{
    height: 68px;
    width: 68px;
    border-radius:5px
}
.cart-item{
    width:50%;
    display:flex;
    flex-direction:column;
    margin-left:15px;
    align-items: baseline;
    justify-content: center;
    margin: 5px
}
.total-bar{
    margin: 15px;
    background-color: white;
    padding: 10px;
    box-shadow: 0px 0px 2px grey;
}
.total-bar h1{
    font-size: 0.8rem;
    margin:5;
    font-weight:300;
    color:black;
}
.total-bar-item{
    display: flex;
    justify-content: space-between;
}
.customaddonitem{
    padding:0px;margin:0px
}
.example-chip{
    padding:12px;
 height: 62px;
margin: 10px 0px;
width: 100%;
}
.cart-sku{
    border: none;
    outline: none;
    resize: none;
    width: 100%;
}
.cart-id-copy{
    position: absolute;
    left: 60px;
}
.cart-id-copy-icon{
    position: absolute;
    left: 1px;
}
.couponcodeh1{
    font-size: 14px;
    margin: 0;
    color: white;
    margin-bottom: 6px;
}
.couponinput{
    background-color: white;
    margin: 8px 0px;
}
.GetItBy{
    font-size: 16px;
    color:green
}
.GetBox{
    margin-top: 10px;
}
.select-location-div{
    margin-top: 10px;
    margin:1rem
}
.location-color{
    color:black;
    font-size: 0.8rem;
}
.select-location-view-cart{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ffffff;
    border-radius: 5px;
    height: 42px;
    box-shadow: 0px 0px 1px #9acee6;
    font-size: 0.8rem;
}