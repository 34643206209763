.my-custom-class{
    padding: 35px;
}
.delete-account-p{
    padding: 15px;
    margin-top: 0;
    padding-top: 0;
}
.delete-account-h2{
    padding: 15px;
    padding-bottom: 0;
    margin-bottom: 0;
}