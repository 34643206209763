.heading {
    font-size: 25px;
    margin-right: 25px;
  }

  .fa {
    font-size: 25px;
  }

  .checked {
    color: orange;
    /* background-color: orange; */
  }

  /* Three column layout */
  .side {
    float: left;
    width: 15%;
    margin-top:10px;
  }

  .middle {
    margin-top:10px;
    float: left;
    width: 70%;
  }

  /* Place text to the right */
  .right {
    text-align: right;
  }

  /* Clear floats after the columns */
  .row:after {
    content: "";
    display: table;
    clear: both;
  }

  /* The bar container */
  .bar-container {
    width: 100%;
    background-color: #f1f1f1;
    text-align: center;
    color: white;
  }

  /* Individual bars */
  .bar-5 { height: 18px; background-color: #04AA6D;}
  .bar-4 { height: 18px; background-color: #2196F3;}
  .bar-3 { height: 18px; background-color: #00bcd4;}
  .bar-2 {height: 18px; background-color: #ff9800;}
  .bar-1 { height: 18px; background-color: #f44336;}

  /* Responsive layout - make the columns stack on top of each other instead of next to each other */
  @media (max-width: 400px) {
    .side, .middle {
      width: 100%;
    }
    .right {
      display: none;
    }
}

