.order-box{
    position: relative;
    padding: 12px;
margin: 10px;
border-radius:5px;
box-shadow: 1px 1px 5px #9e9e9e;
background-color: white;
width: 95%
}
.order-box-payment{
    color: red;
    height: 20px;
    font-size: 10px;
}
.order-box-payment-success{
    color: green;
    height: 20px;
    font-size: 0.8rem;
}
.order-box-total{
    margin-left:2px;
    color: black;
}
.order-pay-btn{
    color:white
}
.order-box-1stdiv{
    display: flex;
}
.order-box-1stdiv-head{
    color:black;
    width: 45%;
    position: relative;
}

.order-box-1stdiv-head p{
   font-size: 0.8rem;
   margin:0;
}
.product-table{
    border: 1px solid;
    padding: 7px;
}
.product-table-item{
    display: flex;
    justify-content: space-between;
}
.product-table-item p{
    margin: 0;
}
.product-table-head{
    display: flex;
    justify-content:space-between;
}
.pay-tag-box{
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.order-code{
    width: 70%;
    display: flex;
    justify-content: center;
    margin-top: -2px;
}
.order-code-div{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.order-id{
    border: none;
    outline: none;
    resize: none;
    width: 100%;
}
.order-id-copy{
    position: absolute;
    position: absolute;
    top: 0;
    left: 63px;
}
