.setup1{
    width:100%;
    height:20rem;
    background: aquamarine;
    display:flex;
    justify-content: center;
}
.setup1 img{
    object-fit: cover;
    width:100%;
    height:20rem;
}