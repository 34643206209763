
.toolbar-container{
    --background: #008000a8;
    align-items: flex-end;
}
.hydrated{
    --color:rgb(78, 74, 74);
    font-family: Cabin,sans-serif;
}
.h1-page{
    margin:0;
    font-size:40;
    font-family: Cabin,sans-serif;
}
.cart-item-length{
    position: absolute;
    z-index: 1;
    margin: 0;
    font-Size: 10px;
    top: 2px;
    left: 9px;
    background-Color: red;
    border-Radius: 100%;
    width: 15px;
    height: 15px;
    display: flex;
    justify-Content: center;
    color:white
}
.search-block{
    padding: 10px;
        position: fixed;
    width: 96%;
    display: block;
    background-color: white;
    height: 60%;
    overflow-y: scroll;
    z-index: 102;
    box-shadow: 0px 0px 5px lightblue;
    left: 7px;
    top: 120px;
}
.search-block-item{
    color: rgb(48, 48, 48);
    display: flex;
    justify-content: center;
    height: 32px;
    align-items: center;
}
.floating-whatsapp{
    height: 65px;
        background: #008000a8;
        border-radius: 50%;
        width: 65px;
        z-index: 105;
        position: fixed;
        bottom: 36px;
        right: 22px;
        display: flex;
        justify-content: center;
        align-items: center;
}

.top-message{
    background: #ffffff;
    /* background: black; */
    color: #008000a8;
    height: 30px;
    display: flex;
    z-index: 0;
    justify-content: center;
    align-items: center;
}
/* @media screen and (max-width: 380px) {
    .hydrated{
        font-size: 15px;
    }
  } */