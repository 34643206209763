.model-cont{
    height: fit-content;
    padding: 12px;
    overflow-y: auto;
}
.model-cont-item{
    background: #8bc34aad;
    color: white;
    margin-bottom: 10px;
    padding: 15px;
    display: flex;
    justify-content: center;
}
.model-cont-item h1{
    margin:0;
}
.modal-head{
    --height: 441px;
}

.modal-wrapper.sc-ion-modal-md{
    height: 350px;
    width: 300px;
}
.div-shop-by-category{
    width:40px;
    height:40px;
    position:relative;
    border-radius:100%;
    background-color:#008000a8
}
.div-shop-by-category-title{
    color: #4d4d4d;
    position:absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width:38px;
    height: 35px;
    top:25px;
    font-size:12px;
    font-family: Cabin,sans-serif;
}
.div-shop-by-category:hover{
    background-color:rgba(63, 70, 63, 0.377);
}

@media screen and (max-width: 380px) {
  .div-shop-by-category-title{
       font-size:10px;
  }
}
