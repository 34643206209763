.viewpage-buybox{
    position: fixed;
    bottom: 0px;
    left: 0;
    background-color: white;
    width: 100%;
    display: flex;
    justify-content: center;
}
.descipt-box{
    display: flex;
    margin-top: 1rem;
}
.descipt-box h1{
   font-size: 0.8rem;
}
.desc-box-cont-selected{
    width: 33%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #008000a8;
    color: white;
    padding: 10px;
    border-radius: 5px;
}
.desc-box-cont{
    width: 33%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f1f1f1;
}

.viewpage-Customization{
    display: -webkit-inline-box;
    width: 100%;
    height: 280px;
    justify-content: space-around;
    overflow-x: auto;
    overflow-y: hidden;
}
.viewpage-custom-box{
    width: 30%;
    border: 2px solid #4caf50;
}
.viewpage-custom-box1{
    width: 30%;
}
.viewpage-custom-box img{
    width: 100%;
    height: 80%;
}
.view-page-img{
    width:"39%"
}
.viewpage-size-chart{
    display: flex;
}
.viewpage-size-chart-span{
        padding: 10px;
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid;
}
.viewpage-size-chart-span:hover{
    background-color: #4caf50;
}
.write-review-box{
    margin-bottom:10rem;
    width:50%
}
.viewpage-customization-box{
    width:20%
}
.viewpage-review-box{
    display: -webkit-inline-box;
    width: 100%;
    height: 280px;
    justify-content: space-around;
    overflow-x: auto;
    overflow-y: hidden;
    height: 220px;
}
.review-box-cont{
    margin:10px 5px;
    padding:5px;
    border-radius: 5px;
    width:40%;
    height: 90%;
    box-shadow:1px 1px 4px rgb(199, 199, 199)
}
.review-user{
    font-size: 52px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: 12px 0;
    margin-bottom: 0;
}
.review-box-username{
    display: flex;
    justify-content: flex-end;
    margin: 0px;
}
.review-box-username p{
    margin:0px;
}
.review-box-cont-desc{
    overflow-y: auto;
    height: 70px;
}
.review-box-cont-desc p{
   margin-bottom: 0;
}
.review-box-star{
    display: flex;
    justify-content: center;
    margin:3px;
}
.noreview-box{
    width: 100%;
    font-size: 20px;
    justify-content: center;
    align-items: center;
    display: flex;
    font-family: cursive;
}
.viewpage-outofstock{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    width: 50%;
}
.viewpage-typevalue{
    width: 110px;
    background: #008000a8;
    position: absolute;
    top: -11px;
    left: 70%;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    border-bottom-left-radius: 10px;
}
.AddCartbtnstyle{
    width:45%;
    color:white;
    height:50px;
    display:flex;
    justify-content: center;
    align-items: center;
}
.GetItBy1{
    font-size: 16px;
    color:green
}
.GetBox1{
    margin-top: 10px;
}
@media screen and (max-width: 550px) {
    .view-page-img{
       width: 100%;
   }
   .review-box-cont{
       width: 100%;
   }
 }
@media screen and (max-width: 710px) {
    .write-review-box{
        width:94%
    }
    .viewpage-customization-box{
        width: 40%;
    }
    .review-box-cont{
        width: 70%;
    }
 }
@media screen and (max-width: 350px) {
    .viewpage-customization-box{
        width: 45%;
    }
    .review-box-cont{
        width: 100%;
    }
 }
