.notification-block{
    padding: 12px;
    height: 230px;
    box-shadow: 0px 1px 5px #b7b7b7;
    position: relative;
    border-radius: 5px;
    width: 90%;
    margin: 1rem auto;
}
.remove-notification{
    position: absolute;
    top: 9px;
    left: 91%;
}