.loader{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.let1 {
  animation: drop 1.2s ease-in-out infinite;
  animation-delay: 0.2s;
  color: #2dd36f;
}

.let2 {
  animation: drop 1.2s ease-in-out infinite;
  animation-delay: 0.3s;
  color: #2dd36f;
}

.let3 {
  animation: drop 1.2s ease-in-out infinite;
  animation-delay: 0.4s;
  color: #2dd36f;
}

.let4 {
  animation: drop 1.2s ease-in-out infinite;
  animation-delay: 0.5s;
  color: #2dd36f;
}

.let5 {
  animation: drop 1.2s ease-in-out infinite;
  animation-delay: 0.6s;
  color: #2dd36f;
}

.let6 {
  animation: drop 1.2s ease-in-out infinite;
  animation-delay: 0.7s;
  color: #2dd36f;
}

.let7 {
  animation: drop 1.2s ease-in-out infinite;
  animation-delay: 0.8s;
  color: #2dd36f;
}
.let8 {
  animation: drop 1.2s ease-in-out infinite;
  animation-delay: 0.9s;
  color: #2dd36f;
}
.let9 {
  animation: drop 1.2s ease-in-out infinite;
  animation-delay: 2.0s;
  color: #2dd36f;
}
.let10 {
  animation: drop 1.2s ease-in-out infinite;
  animation-delay: 2.1s;
  color: #2dd36f;
}


@keyframes drop {
    10% {
        opacity: 0.5;
    }
    20% {
        opacity: 1;
        top: 3.78em;
        transform: rotateX(-360deg);
    }
    80% {
        opacity: 1;
        top: 3.78em;
        transform: rotateX(-360deg);
    }
    90% {
        opacity: 0.5;
    }
    100% {
        opacity: 0;
        top: 6.94em
    }
}